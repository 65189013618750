import { h, Fragment, render } from 'preact'
import { useRef, useState } from 'preact/hooks'
import { text } from './text'
import { buttonBox } from './button-box'
import { button } from './button'
import cars from '../../../shared/cars.json'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from './query-client'
import { FormInput, FormSelect, FormTimeSelect } from './components'
import { useCalculate } from './use-calculate'
import { czkFormatter } from './utils'
import { openForm } from '../modal'

function Price({ code, price }) {
  const suffix = () =>
    price.isWeekend ? 'víkendová akce' : `${czkFormatter.format(price.day)}/den`

  if (code === 'NO_DURATION') return `od ${czkFormatter.format(price)}`

  if (code === 'NO_CITY') {
    return `od ${czkFormatter.format(price.total)} (${suffix()})`
  }

  if (code !== 'OK') return '-'

  return `${czkFormatter.format(price.total)} (${suffix()})`
}

export function Preform({ id }) {
  const car = cars[id]
  const form = useRef(null)
  const [minDate, setMinDate] = useState(() => new Date().toISOString().slice(0, 16))
  const { data, isFetching, refetch } = useCalculate(form)
  const onSubmit = event => {
    event.preventDefault()
    const { dateFrom, timeFrom, dateTo, timeTo, cityFrom, otherCity } = form.current.elements
    openForm({
      dateFrom: dateFrom.value,
      timeFrom: timeFrom.value,
      dateTo: dateTo.value,
      timeTo: timeTo.value,
      cityFrom: cityFrom.value,
      otherCity: otherCity && otherCity.value ? otherCity.value : '0',
    })
  }
  const [selectedCity, setSelectedCity] = useState('')
  const handleCityChange = event => {
    setSelectedCity(event.target.value)
  }

  return (
    <form ref={form} onChange={refetch} onSubmit={onSubmit}>
      <div class="grid grid-cols-1 sm:grid-cols-2 mb-4 gap-x-8 gap-y-6">
        <input type="hidden" name="carId" value={id} />
        <FormInput
          label="Datum půjčení"
          onChange={e => setMinDate(e.currentTarget.value)}
          min={new Date().toISOString().slice(0, 10)}
          type="date"
          name="dateFrom"
        />
        <FormTimeSelect label="Čas půjčení" name="timeFrom" />
        <FormInput label="Datum vrácení" min={minDate} type="date" name="dateTo" />
        <FormTimeSelect label="Čas vrácení" name="timeTo" />
        <FormSelect
          label="Místo půjčení"
          name="cityFrom"
          className="sm:col-span-2"
          onChange={handleCityChange}
        >
          <option value="">Vyberte</option>
          <option value="1">Frýdlant n.O.</option>
          <option value="2">Frýdek-Místek</option>
          <option value="3">Ostrava</option>
          <option value="4">Valašské Meziříčí</option>
          <option value="5">Olomouc</option>
          <option value="6">Jiné</option>
        </FormSelect>
        {selectedCity === '6' && (
          <FormInput label="Zadejte místo" className="sm:col-span-2" name="otherCity" />
        )}
      </div>
      <div class="flex justify-between items-center px-4 py-2 bg-orange-100 rounded mb-2">
        <p class={text({ size: 'small', weight: 'semibold' })}>Cena</p>
        <p class={text({ size: 'small', align: 'right', weight: 'semibold' })}>
          {data ? <Price isFetching={isFetching} {...data} /> : null}
        </p>
      </div>
      {data?.price?.save ? (
        <div class="flex justify-between items-center px-4 py-2 bg-indigo-100 rounded mb-2">
          <p class={text({ size: 'small', weight: 'semibold' })}>
            Vyzvedněte si vozidlo ve Frýdlantu a ušetřete
          </p>
          <p class={text({ size: 'small', align: 'right', weight: 'semibold' })}>
            -{czkFormatter.format(data.price.save)}
          </p>
        </div>
      ) : null}
      <div class="flex justify-between items-center px-4 py-2 bg-slate-100 rounded mb-4">
        <p class={text({ size: 'small' })}>Kauce</p>
        <p class={text({ size: 'small', align: 'right', weight: 'semibold' })}>{car.kauce}</p>
      </div>
      <div class={buttonBox()}>
        <button class={button({ className: 'w-full mb-2 text-center' })}>
          Získej nezávaznou nabídku
        </button>
      </div>
    </form>
  )
}

function App(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <Preform {...props} />
    </QueryClientProvider>
  )
}

const preactRoot = document.querySelector('#preform')
if (preactRoot) {
  render(<App id={window.__initial_state__.car} />, preactRoot)
}
